<template>
  <el-dialog 
    width="35%" 
    :visible="dialogFormVisible" 
    :center="true"
    @close="close"
    id="downloadDialog"
  >
    <template slot="title">
      <div class="title" />
    </template>
    <div class="formTitle">{{$t('plugIn.dialogTitle')}}</div>
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('plugIn.firstName')" prop="Firstname">
            <el-input v-model="form.Firstname"></el-input>
          </el-form-item>      
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('plugIn.lastName')" prop="Lastname">
            <el-input v-model="form.Lastname"></el-input>
          </el-form-item>  
        </el-col>
      </el-row>  
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('plugIn.Email')" prop="Email">
            <el-input v-model="form.Email"></el-input>
          </el-form-item>   
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('plugIn.company')" prop="Company">
            <el-input v-model="form.Company"></el-input>
          </el-form-item>
        </el-col>
      </el-row>  
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="formBtn" @click="download">{{$t('plugIn.download')}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { toggleError } from "@/utils/common";
import {setItem } from "@/utils/storage";
import { Vika } from '@vikadata/vika'
export default {
  name: "",
  data() {
    return {
      form: {
        Firstname: '',
        Lastname: '',
        Email: '',
        Company: ''
      },
      rules: {
        Firstname: [
          { required: true, trigger: 'blur' }
        ],
        Lastname: [
          { required: true, trigger: 'blur' }
        ],
        Email: [
          { required: true, trigger: 'blur' }
        ],
         Company: [
          { required: true, trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    dialogFormVisible: {
      required: true,
      type: Boolean
    },
    downloadForm: {
      required: true,
      type: Object
    }
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit('downloadClose',false)
      this.$refs.ruleForm.resetFields()
      this.form =  {
        Firstname: '',
        Lastname: '',
        Email: '',
        Company: ''
      }
    },
    download() {
      this.$refs.ruleForm.validate(async valid=>{
        if (valid) {
          Vika.auth({
            token: 'uskKgCKKdsFuSyTTPRQJvj7'
          });
          const newRecord1 = {
            fields: this.form
          }
          const {code} = await Vika.datasheet("dsto9cnJmXbEti8bFe").add([newRecord1])
          if (code === 200) {
            const a  = document.createElement('a')
            a.href = this.downloadForm.url
            a.download = this.downloadForm.name
            a.click()
            a.remove()
            setItem('DownloadCompleted',true)
            this.$emit('downloadClose',false)
            this.form =  {
              firstName: '',
              lastName: '',
              Email: '',
              company: ''
            }
          }
        } else {
          toggleError('请将个人信息填写完整','Please fill in the personal information completely') 
        }
      })
    }
  },
}
</script>
<style lang='scss'>
#downloadDialog {
  .el-dialog {
    padding: 0;
    border-radius: 0.625rem;
    overflow: hidden;
    .formTitle {
      width: 100%;
      color: #000;
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      margin: .5rem auto;
    }
    .el-dialog__header {
      background-color: #fafafa;
      padding: 0.625rem 1.25rem;
      .title {
        margin: 0 auto;
        width: 11.4375rem;
        height: 2.875rem;
        background: url("~@/assets/logo.png") no-repeat;
        background-size: 140% 100%;
      }
      .el-dialog__headerbtn {
        font-size: 1.375rem;
      }
    }
    .el-form-item {
      margin-bottom: 1.25rem;
    }
    // .el-dialog--center .el-dialog__body {
    //   padding: 0 25px !important;
    // }
    .el-dialog__footer {
      padding: 0;
      padding-bottom: 30px;
      .dialog-footer {
        .formBtn {
          width: 95%;
          background-color: #ffc322;
          color: #fff;
          border: none;
          // margin-bottom: 30px;
        }
      }
    }
  }
}
  
</style>